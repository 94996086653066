import { FiltersValues } from 'components/UnitsFilters/types';
import qs from 'qs';
import {
  omit,
  assign,
  pick,
  isEmpty,
  isEqual,
  cloneDeep,
  isNil,
  omitBy,
} from 'lodash';
import { defaultFilterValues } from '../../UnitsFilters/constants';
import { NextRouter } from 'next/router';
import {
  BuildingsFilter,
  CitiesFilter,
  CountiesFilter,
  NeighborhoodsFilter,
  SearchBoxFilters,
} from 'components/Search/SearchInput/types';
import { client } from 'apolloClient/client';
import { GET_SEARCHBOX_URL_FILTERS } from 'apolloClient/queries/searchPage';
import { CitiesResponse } from 'apolloClient/types/City';
import { CountiesResponse } from 'apolloClient/types/County';
import { BuildingsResponse } from 'apolloClient/types';
import { NeighborhoodsResponse } from 'apolloClient/types/Neighborhood';
import { flattenStrapiBulkDataItems } from 'lib/flattenStrapiBulkDataItems';
import {
  addBuildinLikeFilter,
  addNeighborhoodFilter,
} from 'components/Search/SearchInput/utils/handleFiltersChange';

type FlatSearchBoxFilters = {
  cities: string[];
  counties: string[];
  neighborhoods: string[];
  buildings: string[];
  addresses: string[];
  zipCodes: string[];
};

export type LinkFilters = Omit<FiltersValues, 'searchBoxFilters'> &
  FlatSearchBoxFilters;

const flatSearchBoxFields: Array<keyof FlatSearchBoxFilters> = [
  'addresses',
  'buildings',
  'cities',
  'counties',
  'neighborhoods',
  'zipCodes',
];

export const getCommonValuesFromQuery = (queryString: string | undefined) => {
  const valuesFromQuery = queryString
    ? (qs.parse(queryString, {
        decoder: (string, defaultDecoder, charset) => {
          const keywords = {
            true: true,
            false: false,
            null: null,
            undefined,
          };

          if (string in keywords) {
            return keywords[string as keyof typeof keywords];
          }
          return defaultDecoder(string, defaultDecoder, charset);
        },
      }) as unknown as Partial<LinkFilters>)
    : {};
  return assign(cloneDeep(defaultFilterValues), valuesFromQuery);
};

export type RelationsFilttersResponse = {
  cities?: CitiesResponse;
  counties?: CountiesResponse;
  neighborhoods?: NeighborhoodsResponse;
  buildings?: BuildingsResponse;
  addresses?: BuildingsResponse;
};

export const addSearchboxRelationsFilters = (
  data: RelationsFilttersResponse,
  filters: SearchBoxFilters
): SearchBoxFilters => {
  const { addresses, buildings, cities, counties, neighborhoods } = data;
  if (cities?.data?.length) {
    filters.cities = cities.data.map((city) => {
      return omit({ id: city.id || 0, ...city.attributes }, ['__typename']);
    });
  }
  if (counties?.data?.length) {
    filters.counties = counties.data.map((county) => {
      return omit({ id: county.id || 0, ...county.attributes }, ['__typename']);
    });
  }
  if (neighborhoods?.data?.length) {
    neighborhoods.data.forEach((neighborhood) => {
      addNeighborhoodFilter({
        filters: filters,
        response: { data: neighborhood },
      });
    });
  }
  if (buildings?.data?.length) {
    buildings.data.forEach((building) => {
      addBuildinLikeFilter({
        filters: filters,
        response: { data: building },
        type: 'buildings',
      });
    });
  }
  if (addresses?.data?.length) {
    addresses.data.forEach((address) => {
      addBuildinLikeFilter({
        filters: filters,
        response: { data: address },
        type: 'addresses',
      });
    });
  }
  return filters;
};

export const getValuesFromQuery = async (
  queryString: string | undefined,
  additionalFilters?: Partial<LinkFilters>
): Promise<FiltersValues> => {
  const valuesFromQuery = getCommonValuesFromQuery(queryString);

  if (additionalFilters) {
    assign(valuesFromQuery, additionalFilters);
  }

  const urlSearchboxFilters = pick(valuesFromQuery, flatSearchBoxFields);
  if (!isEmpty(urlSearchboxFilters)) {
    const newSearchBoxFilters: SearchBoxFilters = {
      cities: [],
      counties: [],
      zipCodes: [],
      neighborhoods: [],
      buildings: [],
      addresses: [],
    };
    if (urlSearchboxFilters.zipCodes) {
      newSearchBoxFilters.zipCodes = urlSearchboxFilters.zipCodes;
    }
    const searchboxFiltersWithoutZipcodes = omit(
      urlSearchboxFilters,
      'zipCodes'
    );
    if (!isEmpty(searchboxFiltersWithoutZipcodes)) {
      const variables = assign(
        {
          loadAddresses: !!urlSearchboxFilters.addresses?.length,
          loadBuildings: !!urlSearchboxFilters.buildings?.length,
          loadCities: !!urlSearchboxFilters.cities?.length,
          loadCounties: !!urlSearchboxFilters.counties?.length,
          loadNeighborhoods: !!urlSearchboxFilters.neighborhoods?.length,
        },
        searchboxFiltersWithoutZipcodes
      );

      const { data } = await client.query<
        RelationsFilttersResponse,
        {
          addresses?: string[];
          buildings?: string[];
          cities?: string[];
          counties?: string[];
          neighborhoods?: string[];
          loadAddresses: boolean;
          loadBuildings: boolean;
          loadCities: boolean;
          loadCounties: boolean;
          loadNeighborhoods: boolean;
        }
      >({
        query: GET_SEARCHBOX_URL_FILTERS,
        variables,
      });
      addSearchboxRelationsFilters(data, newSearchBoxFilters);
    }
    assign(valuesFromQuery, { searchBoxFilters: newSearchBoxFilters });
  }
  return omit(valuesFromQuery, [...flatSearchBoxFields, 'page', 'ppc', 'att']);
};

export function getQueryStringFromAsPath(asPath: string): string {
  return asPath.split('?')[1];
}

export const generateSearchPageLink = (
  filters: Partial<FiltersValues>,
  defaultValues: FiltersValues
) =>
  `/search/?${qs.stringify(
    getParamsForUrl(
      omit(filters, ['createdAt', 'updatedAt', 'alertFrequency', 'name']),
      defaultValues
    ),
    {
      encodeValuesOnly: true,
    }
  )}`;

export function setQueryParams(
  filters: FiltersValues,
  defaultValues: FiltersValues,
  router: NextRouter
) {
  if (isEqual(filters, defaultValues)) {
    router.push('/search', undefined, { shallow: true });
    return;
  }
  const newPath = generateSearchPageLink(filters, defaultValues);
  if (newPath === router.asPath) return;
  router.push(newPath, undefined, {
    shallow: false,
  });
}

const flattenSearchBoxFilters = (
  rootType: 'cities' | 'counties' | 'neighborhoods' | 'addresses' | 'buildings',
  acc: FlatSearchBoxFilters,
  filterItem?:
    | CitiesFilter[]
    | NeighborhoodsFilter[]
    | BuildingsFilter[]
    | CountiesFilter[]
): FlatSearchBoxFilters =>
  (filterItem as CitiesFilter[])?.reduce((acc, { slug, name }) => {
    acc[rootType].push(rootType === 'addresses' ? name : slug);
    return acc;
  }, acc) || acc;

export function getParamsForUrl(
  filters: Partial<FiltersValues>,
  defaultValues: FiltersValues
) {
  const params: Partial<LinkFilters> = {};
  (Object.keys(filters) as (keyof FiltersValues)[]).forEach((key) => {
    if (
      !isEqual(filters[key], defaultValues[key]) &&
      key !== 'searchBoxFilters'
    ) {
      const value = filters[key];
      (params[key] as unknown) = value;
    }
  });
  const { searchBoxFilters } = filters;
  if (searchBoxFilters) {
    let flatSearchBoxFilters: FlatSearchBoxFilters = {
      addresses: [],
      buildings: [],
      cities: [],
      counties: [],
      neighborhoods: [],
      zipCodes: searchBoxFilters.zipCodes,
    };
    flatSearchBoxFilters = flattenSearchBoxFilters(
      'cities',
      flatSearchBoxFilters,
      searchBoxFilters?.cities
    );
    flatSearchBoxFilters = flattenSearchBoxFilters(
      'counties',
      flatSearchBoxFilters,
      searchBoxFilters?.counties
    );
    flatSearchBoxFilters = flattenSearchBoxFilters(
      'neighborhoods',
      flatSearchBoxFilters,
      searchBoxFilters?.neighborhoods
    );
    flatSearchBoxFilters = flattenSearchBoxFilters(
      'buildings',
      flatSearchBoxFilters,
      searchBoxFilters?.buildings
    );
    flatSearchBoxFilters = flattenSearchBoxFilters(
      'addresses',
      flatSearchBoxFilters,
      searchBoxFilters?.addresses
    );
    Object.assign(params, omitBy(omitBy(flatSearchBoxFilters, isNil), isEmpty));
  }
  return omitBy(omit(params, 'searchBoxFilters'), isNil);
}
