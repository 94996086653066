import { useRouter } from 'next/router';
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { ATT, UNIT_DISPLAY_COUNTER } from 'src/constants/constants';
import { getATTFromLocalStorage, wasOnceLogged } from 'src/utils/utils';
import { getQueryStringFromAsPath } from './Search/utils/searchUtils';
import qs from 'qs';

export const PPCContext = createContext({ isPPC: false });

interface Props {
  children?: React.ReactNode;
}

const PPCProvider: React.FC<Props> = ({ children }) => {
  const [isPPC, setIsPPC] = useState(false);
  const { asPath } = useRouter();
  const pathQuery = useMemo(
    () => qs.parse(getQueryStringFromAsPath(asPath)),
    [asPath]
  );

  const savePPCAttribute = useCallback(() => {
    if (wasOnceLogged()) return;
    const query = pathQuery;
    const queryKeys = new Set(Object.keys(query));
    if (queryKeys.has('ppc') && !getATTFromLocalStorage()) {
      const att: string =
        query[ATT] && !Array.isArray(query[ATT]) ? (query[ATT] as string) : '0';
      localStorage.setItem(ATT, att);
      localStorage.setItem(UNIT_DISPLAY_COUNTER, '0');
      setIsPPC(true);
    }
  }, [pathQuery]);

  useEffect(() => {
    savePPCAttribute();
  }, [asPath]);

  return (
    <PPCContext.Provider value={{ isPPC }}>{children}</PPCContext.Provider>
  );
};

export const usePPC = () => useContext(PPCContext);

export default PPCProvider;
