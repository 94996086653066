import React, { ComponentType, useCallback, useEffect, useState } from 'react';
import { Crisp } from 'crisp-sdk-web';
import { useAuth } from 'components/Auth/AuthProvider';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_FUB_BY_ID } from 'apolloClient/queries/followUpBoss';
import {
  SEND_CRISP_MSGS_AS_NOTES_TO_FUB,
  UPDATE_FUB_USER,
} from 'apolloClient/mutations/followUpBoss';
import { wasOnceLogged, getbrowserUUIDFromLocalStorage } from 'src/utils/utils';

let tempMessage: unknown[] = [];

export default function withCrispChat<T>(Component: ComponentType<T>) {
  return function ComponentWithCrispChat(props: T) {
    const [messages, setMessages] = useState<unknown[]>([]);
    const [initialFubAssign, setInitialFubAssign] = useState<boolean>(true);
    const [sessionId, setSessionId] = useState('');
    const { isLogged, me } = useAuth();
    const [getFubById] = useLazyQuery(GET_FUB_BY_ID);
    const [updateFubUser] = useMutation(UPDATE_FUB_USER);
    const [sendCrispMsgsToFub] = useMutation(SEND_CRISP_MSGS_AS_NOTES_TO_FUB);

    useEffect(() => {
      Crisp.configure('ad1b122a-7549-4b4f-87e9-f78be8aa3aac', {
        safeMode: true,
      });

      Crisp.chat.onChatOpened(() => {
        registerCrispHandlers();
      });
    }, []);

    const SendCrispMessagesToFub = useCallback(
      (msgs = messages) => {
        if (!msgs.length) return;
        const leadId = localStorage.getItem('leadId');
        if (!leadId) return;

        sendCrispMsgsToFub({
          variables: {
            fubId: +leadId,
            messages: msgs,
          },
        }).finally(() => {
          setMessages([]);
        });
      },
      [messages, sendCrispMsgsToFub]
    );

    if (typeof window === 'undefined') return <Component {...props} />;

    const wasLoggedBefore = localStorage && wasOnceLogged();

    async function AssignFubLeadDataToCrisp() {
      const leadId = localStorage.getItem('leadId');
      if (!leadId || !initialFubAssign) return;

      setInitialFubAssign(false);

      const {
        data: { getPeopleById: fubUser },
      } = await getFubById({
        variables: {
          id: +leadId,
        },
      });

      if (!fubUser) return;

      if (fubUser.emails.length > 0) {
        Crisp.user.setEmail(fubUser.emails[0].value);
      }

      if (fubUser.phones.length > 0) {
        Crisp.user.setPhone(fubUser.phones[0].value);
      }

      if (fubUser.name.length > 0) {
        Crisp.user.setNickname(fubUser.name[0].value);
      }

      const leadName =
        fubUser.name != null
          ? fubUser.name
          : fubUser.emails.length > 0
          ? fubUser.emails[0].value
          : fubUser.phones.length > 0
          ? fubUser.phones[0].value
          : '';

      Crisp.session.setData([
        [
          [
            'lead_fub_assignment',
            `${fubUser.assignedTo} is assigned to ${leadName} in FUB. https://hbroswell2.followupboss.com/2/people/view/${fubUser.id}`,
          ],
        ],
      ]);
      SendCrispMessagesToFub();

      setSessionId(Crisp.session.getIdentifier() ?? '');
    }

    function updateFubLead() {
      const leadId = localStorage.getItem('leadId') || 0;

      const email = Crisp.user.getEmail();
      const phone = Crisp.user.getPhone();
      const nickname = Crisp.user.getNickname();
      const nicknameIsPhone =
        nickname != null &&
        nickname.startsWith('+') &&
        !isNaN(+nickname.substring(1));
      const phoneToUse =
        phone != null ? phone : nicknameIsPhone ? nickname : null;

      const browserUUID = getbrowserUUIDFromLocalStorage() || undefined;

      updateFubUser({
        variables: {
          fubId: +leadId,
          email: email,
          phone: phoneToUse,
          name: nickname,
          browserUUID,
        },
      }).then((data) => {
        if (data?.data?.updateFollowUpBossUser) {
          const updatedLeadId = data?.data?.updateFollowUpBossUser?.id;
          // Update the leadId in localStorage if it has changed
          if (updatedLeadId && leadId !== updatedLeadId.toString()) {
            localStorage.setItem('leadId', updatedLeadId.toString());
          }

          sendCrispMsgsToFub({
            variables: {
              fubId: +updatedLeadId,
              messages: tempMessage,
            },
          }).finally(() => {
            setMessages([]);
          });
        }
      });
    }

    function onEmailChanged(email: string) {
      if (!isLogged && !wasLoggedBefore) {
        updateFubLead();
      }
    }
    function onPhoneChanged(phone: string) {
      if (!isLogged && !wasLoggedBefore) {
        updateFubLead();
      }
    }
    function onNicknameChanged(nickname: string) {
      if (!isLogged && !wasLoggedBefore) {
        updateFubLead();
      }
    }

    function onMessageSent(message: unknown) {
      const newMessages = [...messages, message];
      setMessages(newMessages);
      tempMessage = [...tempMessage, ...newMessages];
      SendCrispMessagesToFub(newMessages);
    }
    function onMessageReceived(message: unknown) {
      const newMessages = [...messages, message];
      setMessages(newMessages);
      SendCrispMessagesToFub(newMessages);
    }

    function registerCrispHandlers() {
      Crisp.session.setData([[['current_url', location.href]]]);
      AssignFubLeadDataToCrisp();

      Crisp.user.onEmailChanged(onEmailChanged);
      Crisp.user.onPhoneChanged(onPhoneChanged);
      Crisp.user.onNicknameChanged(onNicknameChanged);

      Crisp.message.onMessageSent(onMessageSent);
      Crisp.message.onMessageReceived(onMessageReceived);
    }

    return <Component {...props} />;
  };
}
