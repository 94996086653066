import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo } from 'react';
import type { AppProps } from 'next/app';
import { ApolloProvider, useMutation, useLazyQuery } from '@apollo/client';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { FeaturedResponse } from 'apolloClient/types/Featured';
import { LoadUserAgentsResponse } from 'apolloClient/types/Agents';
import { LOAD_USER_AGENTS } from 'apolloClient/queries/agents';
import { FEATURED_CONDOS } from 'apolloClient/queries/featured';
import { CREATE_ACTIVITY } from 'apolloClient/mutations/activities';
import { client, setLogoutHandler } from 'apolloClient/client';

import {
  getAuthDataFromCookies,
  getAuthDataFromSessionStorage,
  isLoginAsUser,
  updateLoginAsUserInCookies,
} from '../src/utils/authUtils';
import {
  saveAgentIdToLocalStorage,
  getAgentDataFromLocalStorage,
  saveAgentDataToLocalStorage,
} from '../src/utils/utils';
import { initialAuthData } from 'src/constants/authConstants';

import '../components/RentVsBuyCalculator/Result/RentVsBuyChart.css';
import '../styles/index.css';
import '../styles/pageBuilderParser.css';
import '../components/RentVsBuyCalculator/Result/RentVsBuyChart.css';
import '../components/RentVsBuyCalculator/Result/RentVsBuyChart.css';

import Layout from '../components/layouts/Layout';
import AuthProvider, { AuthContext } from '../components/Auth/AuthProvider';
import ToTheTop from 'components/ToTheTop';
import SavedSearchProvider from 'components/SavedHouses/SavedSearchContext';
import {
  getQueryStringFromAsPath,
  getCommonValuesFromQuery,
} from 'components/Search/utils/searchUtils';
import { pageview } from 'src/ga';
import SnackbarProvider from 'components/SnackBar/SnackbarProvider';
import FavoritesListingsProvider from 'components/SavedHouses/SavedFavoritesContext';
import PopUpJoinOrSignIn from 'components/Auth/PopUpJoinOrSignIn';
import PopUpNewPassword from 'components/Auth/PopUpNewPassword';
import HeaderProvider from '../components/layouts/Header/HeaderProvider';
import PPCProvider from 'components/PPCProvider';

const getPageCodeAndCategory = (params: {
  blog: string | undefined;
  unit: string | undefined;
  building: string | undefined;
  path: string | undefined;
}): { code: string; category: string; isDataSearch: boolean } => {
  if (params.blog) {
    return {
      code: params.blog,
      category: 'blog',
      isDataSearch: false,
    };
  }

  if (params.unit) {
    return {
      code: params.unit,
      category: 'unit',
      isDataSearch: false,
    };
  }

  if (params.building) {
    return {
      code: params.building,
      category: 'building',
      isDataSearch: false,
    };
  }

  if (params.path) {
    const code =
      params.path === '/'
        ? 'home'
        : params.path.indexOf('search') > 0
        ? 'search'
        : '';

    return {
      category: 'common',
      code: code,
      isDataSearch: params.path.indexOf('/search') === 0,
    };
  }

  return {
    category: 'unknown',
    code: '',
    isDataSearch: false,
  };
};

type Logout = () => void;

type AppoloLogoutSubscriberProps = {
  setLogoutHandler: (p: Logout) => void;
  logout: Logout;
};

const ApolloClientLogoutSubscriber: React.FC<AppoloLogoutSubscriberProps> = ({
  logout,
  setLogoutHandler,
}) => {
  useEffect(() => {
    setLogoutHandler(logout);
  }, [setLogoutHandler, logout]);
  return null;
};

type WrappedAppType = AppProps & {
  featuredCondos: FeaturedResponse;
};

function WrappedApp({ Component, pageProps, featuredCondos }: WrappedAppType) {
  const { asPath, query } = useRouter();
  const pathQuery = useMemo(
    () => qs.parse(getQueryStringFromAsPath(asPath)),
    [asPath]
  );
  const [loadUserAgents, { data: agentsData, loading: agentsLoading }] =
    useLazyQuery<{
      getCustomUserAgents: LoadUserAgentsResponse;
    }>(LOAD_USER_AGENTS, { client });

  const [createActivity] = useMutation(CREATE_ACTIVITY, { client });

  const authData = useMemo(() => {
    if (typeof window === 'object') {
      return !!getAuthDataFromSessionStorage().jwt
        ? getAuthDataFromSessionStorage()
        : getAuthDataFromCookies(document.cookie);
    } else {
      return initialAuthData;
    }
  }, []);

  useEffect(() => {
    if (!isLoginAsUser()) {
      updateLoginAsUserInCookies(false);
    }
  }, []);

  useEffect(() => {
    if (agentsData) {
      try {
        saveAgentDataToLocalStorage(
          JSON.stringify(agentsData.getCustomUserAgents)
        );
      } catch (error) {
        console.error(error);
      }
    }
  }, [agentsData]);

  const registerActivity = useCallback(() => {
    if (!!isLoginAsUser()) return;
    const authenticatedUser = authData?.user;
    const referer = document.referrer;
    const building = query['urlBuildParam']?.toString();
    const mlsUnit = query['urlUnitParam']?.toString();
    const blog = query['urlArticle']?.toString();

    const { code, category, isDataSearch } = getPageCodeAndCategory({
      blog: blog ? blog : asPath.includes('/blog') ? '/' : '',
      unit: mlsUnit,
      building,
      path: asPath,
    });

    const leadId = localStorage.getItem('leadId');
    let browserUUID = localStorage.getItem('browserUUID');
    if (!browserUUID) {
      const uuid = uuidv4();

      localStorage.setItem('browserUUID', uuid);
      browserUUID = uuid;
    }

    const activity = {
      isAuthenticated: authenticatedUser?.id != null,
      pageUrl: asPath || referer,
      pageTitle: !asPath.split('?').includes('forRent=true')
        ? document.title
        : ' Miami FL  Rental Listings ',
      pageUrlReferrer: referer,
      userName: authenticatedUser?.username || undefined,
      fromAgent: pathQuery.agent?.toString(),
      buildingSlug: building,
      mslUnitSlug: mlsUnit,
      blogSlug: blog,
      pageCategory: category,
      pageCode: code,
      isDataSearch,
      leadId: leadId && +leadId,
      browserUUID: browserUUID || undefined,
      searchFilter:
        code === 'search' && isDataSearch
          ? getCommonValuesFromQuery(getQueryStringFromAsPath(asPath))
          : null,
    };

    createActivity({ variables: { data: { ...activity } } }).then(
      ({ data }) => {
        const newLeadId = data?.createActivity?.data?.attributes?.leadId;
        const newAgentId = data?.createActivity?.data?.attributes?.agentId;
        const resultAgentData = getAgentDataFromLocalStorage();
        let isAgentDataShouldBeUpdated = false;

        if (newLeadId && (!leadId || leadId !== newLeadId + '')) {
          localStorage.setItem('leadId', newLeadId);
          isAgentDataShouldBeUpdated = true;
        }

        if (!isAgentDataShouldBeUpdated && newAgentId && resultAgentData) {
          try {
            const agentsObject = JSON.parse(resultAgentData);
            const mainAgentId =
              agentsObject?.secondAgent?.id || agentsObject?.commonAgent?.id;
            isAgentDataShouldBeUpdated = newAgentId !== mainAgentId;
          } catch (error) {}
        }

        if (!resultAgentData || isAgentDataShouldBeUpdated) {
          loadUserAgents({
            variables: {
              leadId: newLeadId + '' || leadId || undefined,
              isForStorage: true,
            },
          });
        }
      }
    );
  }, [asPath, authData.user, createActivity, pathQuery.agent, query]);

  const executeWhenIdle = () => {
    if ('requestIdleCallback' in window) {
      requestIdleCallback(registerActivity);
    } else {
      setTimeout(registerActivity, 2000);
    }
  };

  useEffect(() => {
    pathQuery.agent && saveAgentIdToLocalStorage(pathQuery.agent as string);
    executeWhenIdle();
    // disabled to verify that activity will be saved only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  useEffect(() => {
    if (query.urlArticle) return;
    pageview(asPath);
  }, [asPath, query.urlArticle]);

  const isHomePage = asPath === '/';
  const isSearchPage = asPath.includes('search');

  return (
    <AuthProvider authDataFromCookie={authData}>
      <AuthContext.Consumer>
        {({ logout }) => (
          <ApolloClientLogoutSubscriber {...{ logout, setLogoutHandler }} />
        )}
      </AuthContext.Consumer>
      <ApolloProvider client={client}>
        <SnackbarProvider>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.RECAPTCHA_PUB_KEY || ''}
          >
            <PPCProvider>
              <SavedSearchProvider>
                <FavoritesListingsProvider>
                  <HeaderProvider>
                    <Layout isHomePage={isHomePage} featured={featuredCondos}>
                      <ToTheTop isSearchPage={isSearchPage} />
                      <PopUpJoinOrSignIn />
                      <PopUpNewPassword />
                      <Component {...pageProps} />
                    </Layout>
                  </HeaderProvider>
                </FavoritesListingsProvider>
              </SavedSearchProvider>
            </PPCProvider>
          </GoogleReCaptchaProvider>
        </SnackbarProvider>
      </ApolloProvider>
    </AuthProvider>
  );
}

WrappedApp.getInitialProps = async () => {
  const featuredResponse = await client.query<{ featured: FeaturedResponse }>({
    query: FEATURED_CONDOS,
  });
  return {
    featuredCondos: featuredResponse.data.featured,
  };
};

export default WrappedApp;
